<template>
  <!-- 成交管理 -->
  <div>
    <!-- 文字 -->
    <div class="text">
      <h4>成交管理</h4>
    </div>
    <!-- 搜索区域 -->
    <div class="form">
      <!-- 手机号查询 -->
      <div>
        <div style="display: flex; margin: 30px 0">
          <el-form label-position="left" label-width="auto" :inline="true">
            <el-form-item label="日期">
              <el-date-picker
                v-model="value"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd 00:00:00"
                @change="pickerChange"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item label="买入用户Uid">
              <el-input v-model="form.buyUserId" placeholder="请输入Uid" style="width: 200px; margin-right: 10px"></el-input>
            </el-form-item>
            <el-form-item label="卖出用户Uid">
              <el-input v-model="form.saleUserId" placeholder="请输入Uid" style="width: 200px; margin-right: 10px"></el-input>
            </el-form-item>
            <el-form-item label="通证">
              <el-select v-model="form.certificateId" placeholder="请选择">
                <el-option v-for="item in options" :key="item.certificateId" :label="item.tokenName" :value="item.certificateId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" style="height: 40px" @click="onNesClassification()">查询</el-button>
              <el-button type="primary" icon="el-icon-search" style="height: 40px" @click="noserch()">重置</el-button>
              <el-button type="primary" icon="el-icon-search" @click="exportData" style="height: 40px">生成表格</el-button>
              <download-excel v-if="derivedForm" class="export" :data="derivedForm" :fields="jsonFields" type="xls" name="成交管理表.xls">
                下载表格
              </download-excel>
            </el-form-item>

            <!-- <el-form-item label="交易类型">
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in optionsTwo" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item> -->
          </el-form>
        </div>
        <!-- 成交量汇总 -->
        <div style="margin-top: 20px">
          <span>成交量汇总：{{ summary.num }}</span>
          <span style="margin: 0 30px">成交总额汇总：{{ summary.price }}</span>
        </div>
      </div>
      <!-- <div>
        <span>按手机号查询</span>
        <el-input
          placeholder="请输入手机号名称"
          v-model="form.phone2"
          clearable
          style="width: 160px; display: block"
          prefix-icon="el-icon-mobile-phone"
        >
        </el-input>
      </div> -->
      <!-- <div>
        <span>按订单号查询</span>
        <el-input
          placeholder="请输入订单号名称"
          v-model="form.phone2"
          clearable
          style="width: 160px; display: block"
          prefix-icon="el-icon-star-off"
        >
        </el-input>
      </div>
      <div class="theQuery-Tow">
        <span>按数字通证查询</span>
        <el-select v-model="approve" placeholder="请选择" style="width: 140px">
          <el-option
            v-for="(item, index) in selectBox"
            :key="index"
            :label="item.tokenNum"
            :value="item.certificateId"
          ></el-option>
        </el-select>
      </div> -->
    </div>
    <!-- 表单区域 -->
    <div style="margin-top: 30px">
      <el-table :data="dataUser" border style="width: 100%">
        <el-table-column prop="id" label="订单号" width="140" align="center"> </el-table-column>
        <el-table-column prop="tokenName" label="数字通证名称" width="140" align="center"> </el-table-column>
        <!-- <el-table-column
          prop="saleName"
          label="类型"
          width="140"
          align="center"
        >
        </el-table-column> -->
        <el-table-column prop="amount" label="成交价" width="" align="center"> </el-table-column>
        <el-table-column prop="number" label="成交量" width="" align="center"> </el-table-column>
        <!-- <el-table-column prop="id" label="手续费" width="" align="center">
        </el-table-column> -->
        <el-table-column prop="totalAmount" label="成交总额" width="" align="center"> </el-table-column>
        <el-table-column prop="serverChange" label="手续费" width="" align="center"> </el-table-column>
        <el-table-column prop="createTime" label="成交时间" width="" align="center"> </el-table-column>
        <el-table-column prop="Call" label="手机号" width="" align="center">
          <template slot-scope="scope">
            <div class="textaer" @click="chakan(scope.$index, scope.row.id)">
              <p>{{ scope.row.buyTel }}</p>
              <!-- <a
              href="/addknockDown"
              @click="chakan(scope.$index, scope.row.id)"
              >{{ scope.row.buyTel }}</a
            > -->
            </div>
            <!-- <el-tag
          :type="scope.row.tag === '家' ? 'primary' : 'success'"
          disable-transitions>{{scope.row.tag}}</el-tag> -->
          </template>
        </el-table-column>
        <el-table-column prop="Name" label="姓名" width="" align="center">
          <template slot-scope="scope">
            <div class="textaer" @click="nametext(scope.$index, scope.row.id)">
              <p>{{ scope.row.buyName }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="businessInfo(scope.row.buyEntrustId)" type="text">买入委托信息</el-button>
            <el-button @click="businessInfo(scope.row.saleEntrustId)" type="text">卖出委托信息</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="委托信息" :visible.sync="centerDialogVisible" width="30%" center>
      <span class="spanstyle">
        <el-form label-position="left" label-width="auto" :model="formLabelAlign">
          <el-form-item label="实际支出金额：">
            <span>{{ formLabelAlign.actualAmount || '暂无' }}</span>
          </el-form-item>
          <el-form-item label="通证类型：">
            <template>
              <span v-if="formLabelAlign.certificateId == 29">乾锦通</span>
              <span v-else-if="formLabelAlign.certificateId == 30">伏羲宝</span>
              <span v-else-if="formLabelAlign.certificateId == 43">小蜜蜂</span>
              <span v-else-if="formLabelAlign.certificateId == 44">泰极数证</span>
              <span v-else-if="formLabelAlign.certificateId == 45">众惠宝</span>
              <span v-else-if="formLabelAlign.certificateId == 46">心洲通</span>
              <span v-else-if="formLabelAlign.certificateId == 48">农鲜通</span>
              <span v-else-if="formLabelAlign.certificateId == 49">汇通宝</span>
              <span v-else-if="formLabelAlign.certificateId == 51">融百业</span>
              <span v-else>暂无</span>
            </template>
          </el-form-item>
          <el-form-item label="是否完成委托：">
            <span v-if="formLabelAlign.finish == 0">未匹配</span>
            <span v-else-if="formLabelAlign.finish == 1">完成委托</span>
            <span v-else-if="formLabelAlign.finish == 2">部分完成</span>
            <span v-else-if="formLabelAlign.finish == 3">撤销</span>
            <span v-else>{{ formLabelAlign.finish || '暂无' }}</span>
          </el-form-item>
          <el-form-item label="委托ID：">
            <span>{{ formLabelAlign.id || '暂无' }}</span>
          </el-form-item>
          <el-form-item label="委托数量：">
            <span>{{ formLabelAlign.num || '暂无' }}</span>
          </el-form-item>
          <el-form-item label="订单编号：">
            <span>{{ formLabelAlign.orderSn || '暂无' }}</span>
          </el-form-item>
          <el-form-item label="委托价格：">
            <span>{{ formLabelAlign.price || '暂无' }}</span>
          </el-form-item>

          <el-form-item label="委托总价格：">
            <span>{{ formLabelAlign.totalPrice || '暂无' }}</span>
          </el-form-item>
          <el-form-item label="委托类型：">
            <span v-if="formLabelAlign.type == 1"> 买入</span>
            <span v-else>卖出</span>
          </el-form-item>
          <el-form-item label="撤销人：">
            <span v-if="formLabelAlign.updateBy == 1"> 系统撤销</span>
            <span v-else>{{ formLabelAlign.updateBy || '暂无' }}</span>
          </el-form-item>
          <el-form-item label="委托人：">
            <span>{{ formLabelAlign.userId || '暂无' }}</span>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="pageNumber"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timeAllocation: '',
      approve: '',
      attestation: [],
      options: [],
      optionsTwo: [
        {
          value: '1',
          label: '买入'
        },
        {
          value: '2',
          label: '卖出'
        }
      ],
      derivedForm: '', //导出的表格数据
      // 导出表单的格式
      jsonFields: {
        订单号: 'id',
        数字通证名称: 'tokenName',
        成交价: 'amount',
        成交量: 'number',
        成交总额: 'serverChange',
        手续费: 'serverChange',
        成交时间: 'createTime',
        手机号: 'buyTel',
        姓名: 'buyName',
        持卡人姓名: 'cardholder',
        状态: 'status',
        失败原因: 'failedReason'
      },
      value: '',
      form: {
        startTime: '',
        endTime: '',
        saleUserId: '',
        buyUserId: '',
        certificateId: '',
        pageSize: 20, //分页大小
        currentPage: 1 //当前页
      },
      formLabelAlign: {},
      dataUser: [],
      total: null,
      currentPage: 1,
      pageNumber: 20,
      centerDialogVisible: false,
      // 成交量汇总
      summary: {},
      selectBox: '' //选择框
    }
  },
  created() {
    this.onNesClassification()
    // 获取通证下拉框
    this.axios.get('/admin/certificate/getDropDownBox', {}).then(res => {
      if (res.data.code == 200) {
        console.log(res, '33333333333333333')
        this.options = res.data.data
      }
    })
  },
  methods: {
    // 跳转
    nametext(index, id) {
      console.log(index, id)
      // this.$router.push({ path: "addknockDown", query: { id } });
    },
    // 跳转
    chakan(index, id) {
      console.log(index, id)
      // this.$router.push({ path: "addknockDown", query: { id } });
    },
    // 获取数据
    onNesClassification() {
      this.axios
        .get('/admin/turnover/adminFindAll', this.form)
        .then(res => {
          console.log(res, '111111111111111111')
          this.dataUser = res.data.data.page.records
          this.summary = res.data.data.count
          this.total = res.data.data.page.total
          this.currentPage = res.data.data.page.current
          // this.pageNumber = 10
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 获取所有需要导出的数据
    exportData() {
      // 注释这里的原因是后端说这个接口太耗资源，该用列表接口
      // this.axios.get('/admin/userWithdraw/withdrawExcel', {}).then(res => {
      this.axios.get('/admin/turnover/getAll', this.form).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          window.open(`http://47.109.88.30:9071/${res.data.data}`)
        } else {
          console('2222')
        }
      })
      // this.axios.get('/admin/userWater/getTime', {}).then(res => {
      //   if (res.data.code == -1) {
      //     this.axios.get('/admin/turnover/getAll', this.form).then(res => {
      //       console.log(res)
      //       if (res.data.code == 200) {
      //         window.open(`http://47.109.88.30:9071/${res.data.data}`)
      //       } else {
      //         console('2222')
      //       }
      //     })
      //     // this.form.pageSize = 200
      //     // this.axios.get('/admin/turnover/adminFindAll', this.form).then(res => {
      //     //   console.log(res)
      //     //   // this.derivedForm = res.data.data
      //     //   this.form.pageSize = 10
      //     //   this.derivedForm = res.data.data.page.records
      //     //   // this.derivedForm.forEach(item => {
      //     //   //   item.status = item.status == 1 ? '审核通过' : item.status == 2 ? '审核失败' : '待审核'
      //     //   //   item.type = item.type == 1 ? '提现' : '充值'
      //     //   // })
      //     // })
      //   } else {
      //     this.$message.error('本时段无法生成表格')
      //   }
      // })
    },
    // 重置
    noserch() {
      this.form.startTime = ''
      this.form.endTime = ''
      this.form.saleUserId = ''
      this.form.buyUserId = ''
      this.form.certificateId = ''
      this.form.pageSize = 10
      this.form.currentPage = 1
      this.onNesClassification()
    },
    // 查看委托信息
    businessInfo(type) {
      this.axios.get('/admin/turnover/getEntust', { id: type }).then(res => {
        this.centerDialogVisible = true
        console.log(res, '111111111111111111')

        this.formLabelAlign = res.data.data
      })
    },
    // 时间
    pickerChange(e) {
      if (e) {
        this.form.startTime = e[0]
        this.form.endTime = e[1]
      } else {
        delete this.form.startTime
        delete this.form.endTime
      }
      // this.form.currentPage = 1
      // this.onNesClassification()
    },
    // 分页
    handleSizeChange(val) {
      console.log(this.pageNumber)
      this.pageNumber = val
      this.axios
        .get('/admin/turnover/adminFindAll', {
          pageNumber: this.currentPage,
          pageSize: this.pageNumber
        })
        .then(res => {
          this.dataUser = res.data.data.page.records
        })
    },
    handleCurrentChange(val) {
      this.currentPage = val
      console.log(this.pageNumber)
      this.axios
        .get('/admin/turnover/adminFindAll', {
          currentPage: this.currentPage,
          pageSize: this.pageNumber
        })
        .then(res => {
          this.dataUser = res.data.data.page.records
        })
    }
  }
}
</script>

<style lang="scss" scoped>
// 导出样式
.export {
  color: #fff;
  background-color: #409eff;
  width: 90px;
  min-width: 90px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  font-size: 12px;
  margin-left: 15px;
  display: inline-block;
}
.export:hover {
  cursor: pointer;
}
.textaer {
  p {
    color: blue;
    text-decoration: underline;
  }
}
.form {
  display: flex;
  div {
    margin-right: 10px;
    span {
      font-weight: bold;
      display: inline-block;
      margin-bottom: 20px;
    }
  }
}
.spanstyle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text {
  h4 {
    margin: 10px;
    color: #409eff;
  }
}
.theQuery {
  height: 70px;
  display: flex;
  margin-left: 30px;
}
.theQuery-Tow {
  margin-bottom: 10px;
  width: 150px;
  height: 60px;
}
.pagination {
  padding: 60px 0 0 100px;
}
</style>
